import angular from 'angular'
import axios from 'axios'
import moment from 'moment'

function EventController ($scope, $rootScope, $document, $interval, $timeout, $routeParams) {
  const vm = this

  let sliderInterval
  vm.PEOPLE_ANIMATION = 'running'
  vm.PEOPLE = []

  vm.EVENT_ID = $routeParams.id
  vm.TICKETS = []

  vm.PROGRAM_GUIDE_URL = ''

  vm.SELECTED_SCHEDULE_STAGE_INDEX = -1
  vm.SELECTED_SCHEDULE_DATE_INDEX = -1
  vm.SELECTED_SCHEDULE_TIME_INDEX = -1

  vm.PAGINATION = {
    EXHIBITORS: {
      list: []
    }
  }

  vm.CONTENT = {
    container1: {
      title: '',
      content: ''
    }
  }

  vm.SCHEDULES = []

  vm.activeTicketIndex = 0

  const eventsSlider = angular.element($document[0].getElementById('events-slider'))[0]

  // Properties
  vm.viewAll = false

  vm.SLIDER = {
    PICTURES: [],
    activeIndex: 0
  }
  vm.EVENT = {
    id: '',
    name: '',
    startDate: '',
    endDate: '',
    daysUntilEvent: 0,
    parsedDate: '',
    ticketPriceFrom: '',
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      country: '',
      postalCode: ''
    }
  }

  // Methods
  vm.startSlider = function () {
    sliderInterval = $interval(() => {
      vm.SLIDER.activeIndex++
      if (vm.SLIDER.activeIndex >= vm.SLIDER.PICTURES.length) {
        vm.SLIDER.activeIndex = 0
      }

      const sliderPictures = eventsSlider.children
      for (let i = 0; i < sliderPictures.length; i++) {
        eventsSlider.children.item(i).classList.remove('active')

        if (i === vm.SLIDER.activeIndex) {
          eventsSlider.children.item(i).classList.add('active')
        }
      }
    }, 3000)
  }

  vm.stopSlider = function () {
    if (angular.isDefined(sliderInterval)) {
      $interval.cancel(sliderInterval)
      sliderInterval = undefined
    }
  }

  vm.changeSlide = function (index) {
    vm.stopSlider()

    vm.SLIDER.activeIndex = index
  }

  vm.toggleViewAll = function () {
    if (vm.viewAll) {
      vm.viewAll = false
    } else {
      vm.viewAll = true
    }
  }

  vm.changePage = function (name, page) {
    vm.PAGINATION[name].currentPage = page
  }

  vm.changeRegionFilter = function (value) {
    vm.FILTERS.REGION.value = value
  }

  vm.changeCityFilter = function (value) {
    vm.FILTERS.CITY.value = value
  }

  vm.changeSortByFilter = function (value) {
    vm.FILTERS.SORT_BY.value = value
  }

  vm.startSlider()

  vm.setPeopleAnimation = function (value) {
    vm.PEOPLE_ANIMATION = value
  }

  vm.changePage = function (name, page) {
    if (page < 0 || page > vm.PAGINATION[name].totalPages) {
      return
    }

    vm.PAGINATION[name].currentPage = page
  }

  const preparePagination = (data, name) => {
    if (!angular.isDefined(vm.PAGINATION[name])) {
      vm.PAGINATION[name] = {}
    }

    vm.PAGINATION[name].list = data
    vm.PAGINATION[name].limit = 18
    vm.PAGINATION[name].currentPage = 0
    vm.PAGINATION[name].totalPages = Math.ceil(vm.PAGINATION[name].list.length / vm.PAGINATION[name].limit)
    vm.PAGINATION[name].paginationHelper = []
    for (let i = 0; i < vm.PAGINATION[name].totalPages; i++) {
      vm.PAGINATION[name].paginationHelper.push(i)
    }
    vm.PAGINATION[name].chunks = []

    const list = []
    for (let i = 0; i < vm.PAGINATION[name].list.length; i += vm.PAGINATION[name].limit) {
      let limit = i + vm.PAGINATION[name].limit
      if (vm.PAGINATION[name].list.length < limit) {
        limit = vm.PAGINATION[name].list.length
      }

      const toPush = vm.PAGINATION[name].list.slice(i, limit)
      while (toPush.length < vm.PAGINATION[name].limit) {
        toPush.push({
          id: 0,
          src: `${window.PUBLIC_PATH}images/il-event-exhibitor-1.webp`,
          title: '-',
          booth: '-'
        })
      }

      list.push(toPush)
    }

    vm.PAGINATION[name].chunks = list
  }

  vm.changeTicketIndex = function (index) {
    vm.activeTicketIndex = index
  }

  vm.celebrationScheduleModalVisible = false
  vm.exhibitorModalVisible = false
  vm.exhibitorModalId = 0
  vm.performerModalVisible = false
  vm.performerModalId = 0
  vm.allExhibitorsModalVisible = false

  vm.toggleCelebrationScheduleModal = function () {
    if (vm.celebrationScheduleModalVisible) {
      vm.celebrationScheduleModalVisible = false
    } else {
      vm.performerModalVisible = false
      vm.allExhibitorsModalVisible = false
      vm.exhibitorModalVisible = false
      vm.celebrationScheduleModalVisible = true
    }
  }

  vm.toggleExhibitorModal = function (id) {
    if (vm.exhibitorModalVisible) {
      vm.exhibitorModalVisible = false
    } else {
      vm.performerModalVisible = false
      vm.allExhibitorsModalVisible = false
      vm.celebrationScheduleModalVisible = false
      vm.exhibitorModalVisible = true
      vm.exhibitorModalId = id
    }
  }

  vm.getExhibitor = function (id) {
    return vm.PAGINATION.EXHIBITORS.list.find(element => element.id === id)
  }

  vm.nextExhibitor = function () {
    const findIndex = vm.PAGINATION.EXHIBITORS.list.findIndex(element => element.id === vm.exhibitorModalId)
    if (findIndex === vm.PAGINATION.EXHIBITORS.list.length - 1) {
      vm.exhibitorModalId = vm.PAGINATION.EXHIBITORS.list[0].id
    } else {
      vm.exhibitorModalId = vm.PAGINATION.EXHIBITORS.list[findIndex + 1].id
    }
  }

  vm.previousExhibitor = function () {
    const findIndex = vm.PAGINATION.EXHIBITORS.list.findIndex(element => element.id === vm.exhibitorModalId)
    if (findIndex === 0) {
      vm.exhibitorModalId = vm.PAGINATION.EXHIBITORS.list[vm.PAGINATION.EXHIBITORS.list.length - 1].id
    } else {
      vm.exhibitorModalId = vm.PAGINATION.EXHIBITORS.list[findIndex - 1].id
    }
  }

  vm.togglePerformerModal = function (id) {
    if (vm.performerModalVisible) {
      vm.performerModalVisible = false
    } else {
      vm.performerModalVisible = true
      vm.performerModalId = id
    }
  }

  vm.getPerformer = function (id) {
    return vm.PEOPLE.find(element => element.id === id)
  }

  vm.nextPerformer = function () {
    const findIndex = vm.PEOPLE.findIndex(element => element.id === vm.performerModalId)
    if (findIndex === vm.PEOPLE.length - 1) {
      vm.performerModalId = vm.PEOPLE[0].id
    } else {
      vm.performerModalId = vm.PEOPLE[findIndex + 1].id
    }
  }

  vm.previousPerformer = function () {
    const findIndex = vm.PEOPLE.findIndex(element => element.id === vm.performerModalId)
    if (findIndex === 0) {
      vm.performerModalId = vm.PEOPLE[vm.PEOPLE.length - 1].id
    } else {
      vm.performerModalId = vm.PEOPLE[findIndex - 1].id
    }
  }

  vm.toggleAllExhibitorsModal = function () {
    vm.allExhibitorsModalVisible = !vm.allExhibitorsModalVisible
  }

  vm.buyTicket = function (ticket) {
    if ($rootScope.cartType === 'old') {
      let url = $rootScope.buyTicketURL
      url = url.replace('{expoid}', $routeParams.id)

      window.location = `${(process.env.NODE_ENV === 'production' ? process.env.APP_URL : process.env.LOCAL_APP_URL)}${url}`
      return
    }

    const CART_ITEMS = $rootScope.getCartItems()

    const hasBooth = CART_ITEMS.findIndex(element => element.itemType === 'booth') !== -1
    if (hasBooth) {
      $rootScope.toggleGenericModal('Add to cart', 'It seems that you already have booth in your cart. Please empty your cart and try again.')
      return
    }

    $rootScope.addItemToCart({
      id: ticket.id,
      itemType: 'ticket',
      name: ticket.title,
      quantity: 1,
      price: ticket.price
    })
  }

  vm.setSelectedScheduleStageIndex = function (index) {
    vm.SELECTED_SCHEDULE_TIME_INDEX = 0
    vm.SELECTED_SCHEDULE_DATE_INDEX = 0
    vm.SELECTED_SCHEDULE_STAGE_INDEX = index
  }

  vm.setSelectedScheduleDateIndex = function (index) {
    vm.SELECTED_SCHEDULE_TIME_INDEX = 0
    vm.SELECTED_SCHEDULE_DATE_INDEX = index
  }

  vm.setSelectedScheduleTimeIndex = function (index) {
    vm.SELECTED_SCHEDULE_TIME_INDEX = index
  }

  vm.getSelectedSchedule = function () {
    return vm.SCHEDULES[vm.SELECTED_SCHEDULE_STAGE_INDEX].options[vm.SELECTED_SCHEDULE_DATE_INDEX].options[vm.SELECTED_SCHEDULE_TIME_INDEX].schedule
  }

  vm.getTicketSize = function () {
    // @screen-lg
    if (window.matchMedia('screen and (min-width: 992px)').matches) {
      return 60
    }

    return 80
  }

  vm.openUrl = function (url) {
    window.open(url, '_blank')
  }

  axios
    .get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/EventScreen.php?eid=${$routeParams.id}` : `${process.env.LOCAL_APP_URL}/jSON/EventScreen.php?eid=${$routeParams.id}`)
    .then((response) => {
      if (response.status === 200) {
        const data = response.data

        vm.SLIDER.PICTURES = data.slideshow.map((element, index) => ({
          src: element,
          active: index === 0
        }))

        vm.TICKETS = []
        for (const ticket of data.tickets) {
          vm.TICKETS.push({
            id: ticket.id,
            title: ticket.title,
            price: parseFloat(ticket.price)
          })
        }

        vm.TICKETS.sort((a, b) => a.price - b.price)

        vm.PROGRAM_GUIDE_URL = data.programguide

        vm.CONTENT.container1 = data.content

        vm.EVENT.id = data.info.id
        vm.EVENT.name = data.info.title
        vm.EVENT.place = data.info.place
        vm.EVENT.startDate = data.info.startDate
        vm.EVENT.endDate = data.info.endDate
        vm.EVENT.daysUntilEvent = moment.utc(data.info.startDate).diff(moment.utc(), 'days')
        vm.EVENT.parsedDate = `${moment.utc(data.info.startDate).format('MMM D')} - ${moment.utc(data.info.endDate).format('D')}`
        vm.EVENT.saturdayParsedTime = `${moment.utc(`1970-01-01 ${data.info.SaturdayExpoStartTime}`).format('h')} - ${moment.utc(`1970-01-01 ${data.info.SaturdayExpoEndTime}`).format('h')}`
        vm.EVENT.sundayParsedTime = `${moment.utc(`1970-01-01 ${data.info.SundayExpoStartTime}`).format('h')} - ${moment.utc(`1970-01-01 ${data.info.SundayExpoEndTime}`).format('h')}`
        vm.EVENT.eventTimings = data.info.eventTimings

        vm.EVENT.ticketPriceFrom = vm.TICKETS.length > 0 ? vm.TICKETS[0].price : 0.00
        vm.EVENT.address = {
          addressLine1: data.info.addressLine1,
          addressLine2: data.info.addressLine2,
          city: data.info.city,
          state: data.info.state
        }
        vm.EVENT.purchaseType = data.info.purchaseType

        const exhibitors = []
        for (const exhibitor of data.exhibitors) {
          exhibitors.push({
            id: exhibitor.id,
            src: exhibitor.src,
            title: exhibitor.title,
            booth: exhibitor.booth,
            link: exhibitor.link,
          //  formattedLink: exhibitor.link.replace(/[https://|http://]/gm, ''),
          formattedLink: exhibitor.link.replace(/[http://]/gm, ''),
            addressLine1: exhibitor.addressLine1,
            city: exhibitor.city,
            state: exhibitor.state,
            pictures: !Array.isArray(exhibitor.pictures) ? [exhibitor.pictures] : exhibitor.pictures,
            facebook: exhibitor.facebookpage,
            shortDescription: exhibitor.shortdescription,
            longDescription: exhibitor.longdescription
          })
        }

        preparePagination(exhibitors, 'EXHIBITORS')

        vm.PEOPLE = []
        for (const customer of data.customers) {
          vm.PEOPLE.push({
            id: customer.id,
            src: customer.src,
            name: customer.name,
            description: customer.description,
            booth: customer.booth,
            addressLine1: customer.addressLine1,
            city: customer.city,
            state: customer.state,
            link: customer.link,
            //formattedLink: customer.link.replace(/[https://|http://]/gm, ''),
            formattedLink: customer.link.replace(/[http://]/gm, ''),
            quoteTitle: customer.quoteTitle,
            quote: customer.quote
          })
        }

        const schedules = data.schedules.map(element => ({
          ...element,
          parsedDate: moment.utc(element.startDate).format('MMMM DD, YYYY'),
         // parsedTime: `${moment.utc(element.startDate).format('hh:mm A')} - ${moment.utc(element.endDate).format('hh:mm A')}`
          parsedTime: `${moment.utc(element.startDate).format('hh:mm a')} `
        }))

        vm.SCHEDULES = []
        for (const schedule of schedules) {
          const parsedStartDate = moment.utc(schedule.startDate)
          const parsedEndDate = moment.utc(schedule.endDate)

          const parsedUnifiedDate = moment.utc(schedule.startDate).hour(0).minute(0).second(0).millisecond(0)

          const findStageIndex = vm.SCHEDULES.findIndex(element => element.stageId === schedule.stageId)
          if (findStageIndex === -1) {
            const newSchedule = {
              stageId: schedule.stageId,
              roomNumber: schedule.roomNumber,
              options: [{
                unix: parsedUnifiedDate.valueOf(),
                date: parsedUnifiedDate.format('dddd, MMMM DD, YYYY'),
                options: [{
                  unix: parsedStartDate.valueOf(),
                //  time: `${parsedStartDate.format('HH:mm')} - ${parsedEndDate.format('HH:mm')}`,
                time: `${parsedStartDate.format('hh:mm a')}`,
                  schedule
                }]
              }]
            }

            vm.SCHEDULES.push(newSchedule)
          } else {
            const findDateIndex = vm.SCHEDULES[findStageIndex].options.findIndex(element => element.unix === parsedUnifiedDate.valueOf())
            if (findDateIndex === -1) {
              const newDate = {
                unix: parsedUnifiedDate.valueOf(),
                date: parsedUnifiedDate.format('dddd, MMMM DD, YYYY'),
                options: [{
                  unix: parsedStartDate.valueOf(),
                 // time: `${parsedStartDate.format('HH:mm')} - ${parsedEndDate.format('HH:mm')}`,
                 time: `${parsedStartDate.format('hh:mm a')} `,
                  schedule
                }]
              }

              vm.SCHEDULES[findStageIndex].options.push(newDate)
            } else {
              const newTime = {
                unix: parsedStartDate.valueOf(),
               // time: `${parsedStartDate.format('H:mm')} - ${parsedEndDate.format('HH:mm')}`,
               time: `${parsedStartDate.format('h:mm a')} `,
                schedule
              }

              vm.SCHEDULES[findStageIndex].options[findDateIndex].options.push(newTime)
            }
          }
        }

        if (vm.SCHEDULES.length > 0) {
          vm.SELECTED_SCHEDULE_STAGE_INDEX = 0
          vm.SELECTED_SCHEDULE_DATE_INDEX = 0
          vm.SELECTED_SCHEDULE_TIME_INDEX = 0
        }

        $scope.$apply()
      }
    })
    .catch(e => alert(e))

  // $scope.$watch('$viewContentLoaded',
  //   function () {
  //     $timeout(function () {
  //       console.log('test')
  //       if ($rootScope.scrollTo !== null) {
  //         angular.element(document).ready(() => {
  //           // eslint-disable-next-line angular/document-service
  //           const findElement = document.querySelector($rootScope.scrollTo)
  //           if (findElement) {
  //             const rect = findElement.getBoundingClientRect()
  //             let top = rect.top - 200
  //             if (top < 0) {
  //               top = 0
  //             }

  //             window.scrollTo({
  //               behavior: 'smooth',
  //               left: 0,
  //               top
  //             })
  //           }
  //         })
  //       }
  //     }, 0)
  //   })
}

angular.module('app').controller('EventController', ['$scope', '$rootScope', '$document', '$interval', '$timeout', '$routeParams', EventController]);

angular.module('app').directive('iframeDirective', ['$sce', '$routeParams', function($sce, $routeParams) {
  return {
    restrict: 'E',
    template: '<iframe src="{{ trustedUrl }}" frameborder="0" allowfullscreen="true" width="100%" height="700"></iframe>',
    link: function(scope) {
      scope.trustedUrl = $sce.trustAsResourceUrl("https://prod2.bmse.net/event/lecture_schedule_grid2.php?eid="+ $routeParams.id);
    }
  }
}]);
